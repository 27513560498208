import React from "react"
import { graphql } from "gatsby"
import ProductPage from '../../components/gallery/productPage'
import Seo from "../../components/SEO"


const Gold = ({data}) => {

    const _title = `Larix Medium Gold Lamp`;


    const _quotes = [
        `The beauty of lava, the utopia of fiery fairies.`,
        `The lighting is concentrated. By watching the shadows dance, my eyes cannot help 
        but stop at the precision of the details and the gold color. The gold is like 
        the skin embracing the body in whole. What do the patterns insinuate? Have they been created 
        through Renaissance, Baroque or maybe Rococo? There is great fascination growing inside
        me wanting to discover more. `,
        `In 1505, I danced with the silence at night. In a mansion in the middle of the golden hall,
        I orientated the candle towards the ceiling, listening to wispers of eternity. `
    ]


    const _properties = {
      "Height": "83 cm",
      "Width": "22 cm",
      "Weight": "3 kg"
    }


    const _walloftext = {
        "Product's purpose": [
            `
                The lamp is meant primarily for ambient lighting. It decorates the space with mild 
                lighting and rich design. The Larix Medium Gold Lamp radiates warmth, softness and 
                gives the room a special silky note.
            `
         ],
        "Materials": [
            `
                The lamp is made entirely of natural materials. 
                The wooden parts are made of solid beech wood, coated with 
                water-soluble acrylics with natural pigments and decorated 
                with oil paints and a pure gold coating.
                The shade is made of high quality silk, embroidered with 
                colorful decorative shapes.
            `
        ],
        "Production": [
            `
                Wooden parts are machine painted and patinated. The painter then hand-paints
                decorative elements with oil paints. The production of the lamp is completed by 
                the restorer by hand application of gilding paste - a paste with fine particles 
                of pure gold. 
            `,
            `
                The base for the shade is high quality silk, to which decorative patterns
                and shapes are machine-embroidered. The master of embroidery has developed the 
                technology to such an extent that needle stitches imitate hand embroidery, 
                which is also evident when looking closely at the embroidery.
            `,
            `
                Lastly, the master stretches the silk over the metal frame 
                and sews upholstery strips to the edges.
            `
        ],
    }



    return (
        <>
            <Seo title={_title} />
            <ProductPage 
                galleryItems={data.allFile}
                title={_title}
                quotes={_quotes}
                properties={_properties}
                walloftext={_walloftext}
                spinnerSprite={{on: data.on, off: data.off}}
            />
        </>
    )
}

export default Gold


export const query = graphql`
query {
    allFile(filter: {relativeDirectory: {eq: "gold"}}, sort: {fields: name}) {
        nodes {
            childImageSharp {
                original {
                    src
                    height
                    width
                }
                webp: resize(width: 334, toFormat: WEBP, pngQuality: 50) {
                    src
                    height
                    width
                }
                png: resize(width: 334, toFormat: PNG, pngQuality: 50) {
                    src
                }
                thumbnail: resize(height: 150, toFormat: WEBP, webpQuality: 80) {
                    src
                    height
                    width
                }
            }
            name
        }
    }
    on: allFile(filter: {relativeDirectory: {eq: "spin/gold_on"}} sort: {fields: name}) {
        nodes {
            childImageSharp {
                webp: resize(height: 700, toFormat: WEBP, webpQuality: 90) {
                    src
                    width
                    height
                }
            }
        }
    }
    off: allFile(filter: {relativeDirectory: {eq: "spin/gold_off"}} sort: {fields: name}) {
        nodes {
            childImageSharp {
                webp: resize(height: 700, toFormat: WEBP, webpQuality: 90) {
                    src
                    width
                    height
                }
            }
        }
    }
}`

